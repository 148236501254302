import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

export default function Project(props) {
  const {
    project,
    logo,
    setProjectVisible,
  } = props;

  const [active, setActive] = useState(false)

  const backTohome = () => {
    setProjectVisible(false);
    document.body.classList.remove('noscroll');
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 10)
  })

  return (
    <>
      <div className="close-project" onClick={() => backTohome()}>
        <i className="icon-x"></i>
      </div>
      
      <div className={classNames('project-wrapper', active && 'active')}>
        <div className="project">
          <div className={classNames('project-header', project.sectionTextLight && 'light')}>

            <div className="title">
              <h3>{project.title}</h3>
              <div className="roles-platforms">
                {project.roleDesign && <div><i className="icon-design"></i> Design</div> }
                {project.roleFrontend && <div><i className="icon-code"></i> Front-end</div> }
                {project.platformMacOS &&<div><i className="icon-apple"></i> MacOS</div> }
                {project.platformIOS && <div><i className="icon-apple"></i> iOS</div> }
                {project.platformWindows && <div><i className="icon-microsoft"></i> Windows</div> }
                {project.platformAndroid && <div><i className="icon-android"></i> Android</div> }
                {project.platformWeb && <div><i className="icon-web"></i> Web - Desktop</div> }
                {project.platformWebMobile && <div><i className="icon-web"></i> Web - Desktop / Mobile</div> }
              </div>
            </div>

            <div 
              className="project-header-bg"
              style={{backgroundColor: `${project.bgColor}`}}
            />
          </div>

          <div className="project-main">
            {project.projectID === "wiseox" &&
              <>
                <h4>Overview</h4>
                <p>WiseOx empowers enterprise businesses to train and personalize their own AI assistants, called "Mascots", which are subject matter experts that can perform tasks and speed up access to institutional knowledge.</p>
                
                <h4>My Role</h4>
                <p>I am the CPO and Co-founder of WiseOx, along with Fritz Brumder and Andy Bown (former co-founders of Zipcan and Brandlive). I designed and built the initial prototype and now oversee all product operations. I perform all design and UI responsibilities, along with managing the engineering team.</p>
                
                <hr />

                <h5>Mascots Home Screen</h5>
                <img src={require('../images/projects-wiseox/mascots.png')} alt="" />

                <h5>Insights Dashboard</h5>
                <img src={require('../images/projects-wiseox/insights.png')} alt="" />

                <h5>Mascot Chat</h5>
                <img src={require('../images/projects-wiseox/mascot-chat-empty.png')} alt="" />
                <img src={require('../images/projects-wiseox/mascot-chat-attachments.png')} alt="" />

                <h5>Mobile Chat</h5>
                <img src={require('../images/projects-wiseox/mascot-chat-mobile.png')} alt="" />

                <h5>Edit Mascot & Train Mascot UX</h5>
                <img src={require('../images/projects-wiseox/edit-mascot.png')} alt="" />

                <h5>Mascot Insights & Metrics</h5>
                <img src={require('../images/projects-wiseox/mascot-insights.png')} alt="" />
              </>
            }

            {project.projectID === "convee" &&
              <>
                <h4>Overview</h4>
                <p>Convee is a pick up service for in-store and online returns where customers can submit their returns in the app and drop them on their doorstep for pickup with or without packaging, receipts, or labels.</p>
                
                <h4>My Role</h4>
                <p>The origin story of Convee stems from the shut down of Hi Touch. With little runway left, the team decided to pivot and try something new. However, we needed to make progress quickly. My team designed and developed a polished MVP and launched it to the App Store and Google Play in under 6 weeks. Since then we've been iterating on new ideas to grow the user base of the app.</p>
                
                <hr />

                <h5>Authentication</h5>
                <img src={require('../images/projects-convee/convee1.png')} alt="" />

                <h5>Home Status</h5>
                <img src={require('../images/projects-convee/convee2.png')} alt="" />

                <h5>Enter a Return Workflow</h5>
                <img src={require('../images/projects-convee/convee3.png')} alt="" />
                <img src={require('../images/projects-convee/convee4.png')} alt="" />

                <h5>Checkout and Success</h5>
                <img src={require('../images/projects-convee/convee5.png')} alt="" />

                <h5>Subscription</h5>
                <img src={require('../images/projects-convee/convee6.png')} alt="" />
              </>
            }

            {project.projectID === "hitouch" &&
              <>
                <h4>Overview</h4>
                <p>Hi Touch was a video-based shopping platform that embeds on a retailer's website. Clients can book time with sales associates and engage in a video styling session and co-browse the retail website with the associate, all within the website.</p>
                
                <h4>My Role</h4>
                <p>My previous company, Zipcan, was acquired by one of our customers (Teamwork Commerce) who spun out Hi Touch as a new business. I was in charge of Product Management along with managing the Engineering team to develop virtual shopping experiences using Zipcan as the underlying technology. Unfortunately the business found little success and had to shut their doors within the first year.</p>

                <hr />

                <h5>Video & Co-browsing Experience</h5>
                <img src={require('../images/projects-hitouch/customer3.png')} alt="" />

                <h5>Product Showroom</h5>
                <img src={require('../images/projects-hitouch/customer5.png')} alt="" />

                <h5>Associate Desktop View</h5>
                <img src={require('../images/projects-hitouch/associate1.png')} alt="" />
                <img src={require('../images/projects-hitouch/associate2.png')} alt="" />
                
                <h5>Mobile App Concept</h5>
                <img src={require('../images/projects-hitouch/mobile1.png')} alt="" />
                <img src={require('../images/projects-hitouch/mobile2.png')} alt="" />
                <img src={require('../images/projects-hitouch/mobile3.png')} alt="" />
                <img src={require('../images/projects-hitouch/mobile4.png')} alt="" />
              </>
            }

            {project.projectID === "zipcan" &&
              <>
                <h4>Overview</h4>
                <p>Zipcan was a low/no-code embeddable video chat platform. It enables businesses to easily create white-labeled video chat experiences that work in context of their existing applications, and explore new video opportunities quickly.</p>
                
                <h4>My Role</h4>
                <p>As the Co-founder and CPO at Zipcan, I led a team of engineers to achieve our vision of putting real-time video in every corner of the web. My goal for the product was to empower our customers to easily enable video chat in their their own web applications. We were acquired by Teamwork Commerce at the end of 2021.</p>

                <hr />

                <h5>Embedded Video Chat</h5>
                <img src={require('../images/projects-zipcan/embed-desktop.png')} alt="" />
                <img src={require('../images/projects-zipcan/embed-mobile.png')} alt="" />

                <h5>Admin Dashboard</h5>
                <img src={require('../images/projects-zipcan/admin-dashboard.png')} alt="" />

                <h5>Chat Space Configuration</h5>
                <img src={require('../images/projects-zipcan/admin-config.png')} alt="" />

                <h5>Chat Space Theming</h5>
                <img src={require('../images/projects-zipcan/admin-videostyle.png')} alt="" />

                <h5>Event Scheduling</h5>
                <img src={require('../images/projects-zipcan/admin-schedule.png')} alt="" />
                <img src={require('../images/projects-zipcan/admin-newevent.png')} alt="" />
              </>
            }

            {project.projectID === "apptoto" &&
              <>
                <h4>Overview</h4>
                <p>Appointment reminders, and client communication tools that streamline your existing calendar workflow.</p>
                
                <h4>My Role</h4>
                <p>I was brought on at Apptoto as a contractor to help align their user experiences and product brand identity. In addition, I designed a mobile experience that would simplify the powerful web portal in an easy to use application for their modern clients.</p>

                <hr />

                <h5>Sign In</h5>
                <img src={require('../images/projects-apptoto/apptoto-signin.png')} alt="" />
                
                <h5>Agenda</h5>
                <img src={require('../images/projects-apptoto/apptoto-agenda.png')} alt="" />
                
                <h5>Messages</h5>
                <img src={require('../images/projects-apptoto/apptoto-messages.png')} alt="" />
                
                <h5>Threads</h5>
                <img src={require('../images/projects-apptoto/apptoto-messages2.png')} alt="" />
                
                <h5>Contacts</h5>
                <img src={require('../images/projects-apptoto/apptoto-contacts.png')} alt="" />
                
                <h5>Appointment</h5>
                <img src={require('../images/projects-apptoto/apptoto-appts.png')} alt="" />
                
                <h5>Voice Message</h5>
                <img src={require('../images/projects-apptoto/apptoto-voicemessage.png')} alt="" />
              </>
            }

            {project.projectID === "producer" &&
              <>
                <h4>Overview</h4>
                <p>Producer is a live video broadcasting app for MacOS and Windows. It is meant to be a lightweight alternative to more complex applications, such as Wirecast, while still offering HD broadcasting and professional features without a large learning curve.</p>
                <p>Our objective was to build a cross-platform application that is powerful and easy to use.</p>
                
                <h4>My Role</h4>
                <p>With this project my job was to design an elegant and simple interface for building shots and going live while also being able to monitor incoming comments from the event. I also had a heavy hand in developing the UI, which was built in React JS with an Electron wrapper.</p>
                
                <hr />

                <h5>Building Shots</h5>
                <img src={require('../images/projects-brandlive/producer-buildshot.png')} alt="producer-buildshot" />
                <img src={require('../images/projects-brandlive/producer-addvideo.png')} alt="producer-addvideo" />

                <h5>Effects</h5>
                <img src={require('../images/projects-brandlive/producer-effects.png')} alt="producer-effects" />

                <h5>Going Live</h5>
                <img src={require('../images/projects-brandlive/producer-shotlive.png')} alt="producer-shotlive" />

                <h5>Priority View</h5>
                <img src={require('../images/projects-brandlive/producer-priority.png')} alt="producer-priority" />
              </>
            }

            {project.projectID === "presenter" &&
              <>
                <h4>Overview</h4>
                <p>Presenter is a web-based video conferencing app for including a remote presenter in a live broadcast.</p>
                <p>Unlike other more complex platforms where remote presenters are not supported, we aimed to solve this as an extension of Producer.</p>
                
                <h4>My Role</h4>
                <p>My objective was to design a simple meeting-like experience and would be familiar for people who have used other meetings apps, but instead of a meeting, it is a broadcast. Additionally, I developed the interface, complete with an onboarding experience and metrics to show your screen time and usage.</p>
                
                <hr />

                <h5>Onboarding</h5>
                <img src={require('../images/projects-brandlive/presenter-onboard.png')} alt="presenter-onboard" />
                <img src={require('../images/projects-brandlive/presenter-onboard2.png')} alt="presenter-onboard2" />

                <h5>Presenting and Sharing</h5>
                <img src={require('../images/projects-brandlive/presenter-active.png')} alt="presenter-active" />
                <img src={require('../images/projects-brandlive/presenter-screenshare.png')} alt="presenter-screenshare" />

                <h5>Wrap Up</h5>
                <img src={require('../images/projects-brandlive/presenter-stats.png')} alt="presenter-stats" />
              </>
            }

            {project.projectID === "admin" &&
              <>
                <h4>Overview</h4>
                <p>Admin Console is the creative and analytical tool for administering Brandlive experiences, such as events and lessons. This application has always been the cornerstone of the Brandlive platform, and underwent a major ground-up redevelopment in 2016/17.</p>
                <p>Our goal was to build a product for our customers that helped them understand their audiences and give them a powerful tool to create custom video experiences for product marketing and retail training.</p>
                
                <h4>My Role</h4>
                <p>I was tasked with designing a brand new experience that replaces the original version of the app. After building a new IA and several design iterations, we developed it in Angular JS, and I built out the user interface, interactions, and theme tool for customizing video pages.</p>
                
                <hr />

                <img src={require('../images/projects-brandlive/admin-dashboard.png')} alt="admin-dashboard" />

                <h5>Event Creation</h5>
                <img src={require('../images/projects-brandlive/admin-event.png')} alt="admin-event" />
                <img src={require('../images/projects-brandlive/admin-event-content.png')} alt="admin-event-content" />
                <img src={require('../images/projects-brandlive/admin-event-theme.png')} alt="admin-event-theme" />

                <h5>Event Activity</h5>
                <img src={require('../images/projects-brandlive/admin-event-overview.png')} alt="admin-event-overview" />

                <h5>Course Activity</h5>
                <img src={require('../images/projects-brandlive/admin-courses.png')} alt="admin-courses" />

                <h5>Content Manager</h5>
                <img src={require('../images/projects-brandlive/admin-content-manager.png')} alt="admin-content-manager" />
              </>
            }

            {project.projectID === "events" &&
              <>
                <h4>Overview</h4>
                <p>Events are the customizable video pages where customers can include broadcast and recorded video for their audiences. Events are the primary medium for viewers of Brandlive content.</p>
                <p>Recently we decided to rebuild event pages in React with an improved mobile web experience. This project was more due diligence for ourselves and to give customers a much faster and rich experience.</p>
                
                <h4>My Role</h4>
                <p>Although I did fully design the experience, it largely stayed the same so the familiarity for our viewers didn’t change. It was more of a refinement project. My biggest involvement was in the redevelopment effort where I built the UI, implemented the user-generated content from the API, and optimized for the responsive mobile web experience.</p>
                
                <hr />
                
                <h5>Events - Desktop</h5>
                <img src={require('../images/projects-brandlive/events-page.png')} alt="events-page" />
                <img src={require('../images/projects-brandlive/events-product.png')} alt="events-product" />

                <h5>Events - Mobile</h5>
                <img src={require('../images/projects-brandlive/events-mobile1.png')} alt="events-mobile1" />
                <img src={require('../images/projects-brandlive/events-mobile2.png')} alt="events-mobile2" />
                <img src={require('../images/projects-brandlive/events-mobile3.png')} alt="events-mobile3" />
              </>
            }

            {project.projectID === "mobile" &&
              <>
                <h4>Overview</h4>
                <p>Brandlive Mobile is an iOS and Android app that is the one-stop-shop for all things Brandlive, so to speak.</p>
                <p>Brandlive’s legacy app was fairly dated and there wasn’t much incentive for viewers to download it. With this new app, we wanted to give viewers a product-forward experience for watching live broadcasts for product announcements and retail training. Additionally, customers could go live right from the app.</p>

                <h4>My Role</h4>
                <p>My role mostly consisted of UX/UI design, but I also contributed to styling the interface and tightening up a variety of interactions to give users a smooth native mobile experience.</p>
                
                <hr />
                
                <h5>Feed and Event View</h5>
                <img src={require('../images/projects-brandlive/mobile-main.png')} alt="mobile-main" />
                
                <h5>Channels</h5>
                <img src={require('../images/projects-brandlive/mobile-channels.png')} alt="mobile-channels" />

                <h5>My Content</h5>
                <img src={require('../images/projects-brandlive/mobile-content.png')} alt="mobile-content" />

                <h5>Course</h5>
                <img src={require('../images/projects-brandlive/mobile-courses.png')} alt="mobile-courses" />

                <h5>Broadcasting</h5>
                <img src={require('../images/projects-brandlive/mobile-broadcast.png')} alt="mobile-broadcast" />
              </>
            }

            {project.projectID === "help" &&
              <>
                <h4>Overview</h4>
                <p>The Help Center was an initiative project that I led in tandem with our support manager to help alleviate the onslaught of questions and issues from viewers to our support staff. After all, a SaaS company needs a support site.</p>

                <h4>My Role</h4>
                <p>My role consisted of designing and developing the Zendesk implementation, as well as co-writing the help articles.</p>
                
                <hr />
                
                <img src={require('../images/projects-brandlive/help-home.png')} alt="help-home" />
                <img src={require('../images/projects-brandlive/help-articles.png')} alt="help-articles" />
                <img src={require('../images/projects-brandlive/help-article.png')} alt="help-article" />
                <img src={require('../images/projects-brandlive/help-mobile.png')} alt="help-mobile" />
              </>
            }

            {project.projectID === "brandlive-composer" &&
              <>
                <h4>Overview</h4>
                <p>Composer is a video marketing automation platform where brands can create their own professional videos and conduct campaigns to stream to their audiences on their social channels, websites, and third party tools.</p>

                <h4>Project</h4>
                <p>This was mostly a brainstorm project for Brandlive as we were looking to build a platform for mid-market brands. After a few weeks of conceptualizing we decided to table the project.</p>
                
                <hr />

                <img src={require('../images/projects-concepts/composer-dashboard.png')} alt="composer dashboard" />
                <img src={require('../images/projects-concepts/composer-campaign.png')} alt="composer campaign" />
                <img src={require('../images/projects-concepts/composer-videos.png')} alt="composer videos" />
                <img src={require('../images/projects-concepts/composer-editor1.png')} alt="composer editor" />
                <img src={require('../images/projects-concepts/composer-editor2.png')} alt="composer editor" />
                <img src={require('../images/projects-concepts/composer-mobile1.png')} alt="composer mobile" />
                <img src={require('../images/projects-concepts/composer-mobile2.png')} alt="composer mobile" />
                <img src={require('../images/projects-concepts/composer-mobile3.png')} alt="composer mobile" />
              </>
            }

            {project.projectID === "modboard" &&
              <>
                <h4>Overview</h4>
                <p>Modboard was a passion project of mine. The goal with the platform is to give people a way to collaborate and be productive all in the same enviroment. This could work well for multiple markets and use cases.</p>
                
                <hr />
                
                <img src={require('../images/projects-concepts/modboard-flowchart.png')} alt="modboard-flowchart" />
                <img src={require('../images/projects-concepts/modboard-research.png')} alt="modboard-research" />
                <img src={require('../images/projects-concepts/modboard-meeting.png')} alt="modboard-meeting" />
                <img src={require('../images/projects-concepts/modboard-spacepopover.png')} alt="modboard-spacepopover" />
                <img src={require('../images/projects-concepts/modboard-videopopover.png')} alt="modboard-videopopover" />
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}